import { apiSlice } from "./apiSlice";

export const siteSlice=apiSlice.injectEndpoints({


    endpoints:(builder)=>({
        userLogin:builder.mutation({
            query:(data)=>({
                url:`/site1/auth`,
                method:'POST',
                body:data
            })
        }),
        redirectPage:builder.mutation({
            query:(data)=>({
                url:`/site1/auth`,
                method:'PUT',
                body:data
            })
        }),
        addRedirection:builder.mutation({
            query:(data)=>({
                url:`/site1/redirection`,
                method:'POST',
                body:data
            })
        }),
        editRedirection:builder.mutation({
            query:(data)=>({
                url:`/site1/redirection`,
                method:'PUT',
                body:data
            })
        }),
        redirections:builder.query({
            query:(data)=>({
                url:`/site1/redirection`,
              
            }),
            keepUnusedDataFor:5,

        })
    })
})


export const {useUserLoginMutation,useRedirectPageMutation,useAddRedirectionMutation,useEditRedirectionMutation,useRedirectionsQuery}=siteSlice