import { ChevronLeftRounded, Edit, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, IconButton, Switch, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Close } from '@mui/icons-material';

import './dashboard.css'
import { toast } from 'react-toastify';
import { useAddRedirectionMutation, useEditRedirectionMutation, useRedirectionsQuery } from '../slices/siteSlice';
export default function MainComponent(props) {
  const [editRedirection1,setEditRedirection]=useState('')

  const {data:redirections,isLoading:loadingRedirection,refetch}=useRedirectionsQuery()

    const [data,setData]=useState('')
    const [data1,setData1]=useState('')

    const [open,setOpen]=useState(false)
    const [open1,setOpen1]=useState(false)

    const handleClose=()=>{
      setOpen(false)
      setOpen1(false)
      setEditRedirection('')
      setData({...data,offer_id:'',pid:'',redirection_url:''})
    
  }
    const [addRedirection,{isLoading:loading}]=useAddRedirectionMutation()
    const [editRedirection,{isLoading:loadingE}]=useEditRedirectionMutation()
const Signout=()=>{
  localStorage.removeItem('userInfo')
  window.location.reload()
}
    const EditRedirection=(val)=>{
        // console.log(val)
        setData({
          ...data,offer_id:val.offer_id,pid:val.pid,redirection_url:val.redirection_url,id:val._id
        })
        
          setEditRedirection(val)
          setOpen1(true)
        }
const editRedirectionClicked=async()=>{

  if(data.offer_id&&data.pid&&data.redirection_url){
   

  
      try {
  
          const res=await editRedirection(data).unwrap()
          if (res){
              toast.success(res.message)
              setOpen1(false)
              setEditRedirection('')
              setData({...data,offer_id:'',pid:'',redirection_url:''})
              refetch()
              } 
      } catch (error) {
          toast.error(error?.data?.message||error.error)
  
      }
   
  }
  else{
    toast.error('Please provide mandatory fields')
  
  }

}

const addRedirectionClicked=async()=>{
if(data.offer_id&&data.pid&&data.redirection_url){
  

    try {

        const res=await addRedirection(data).unwrap()
        if (res){
            toast.success(res.message)
            setOpen1(false)
            refetch()
            } 
    } catch (error) {
        toast.error(error?.data?.message||error.error)

    }
 
}
else{
  toast.error('Please provide mandatory fields')

}
   

}


// console.log(redirections)

  return (

    <div>
{loadingRedirection?<div style={{marginTop:'20vh',textAlign:'center',}}>
    <CircularProgress  style={{color:'#ec7539'}}/>
</div>:
    <Grid container>


<Dialog
        open={open1}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{fontSize:22,fontWeight:'bold',color:'var(--blueColor)'}}>{editRedirection1?'Edit URL':'Add URL'}</DialogTitle>
 <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
        

        <DialogContent>

<Grid container>

<Grid item sm={12} style={{padding:'2vh 20px'}} xs={12}>

       
        <TextField   style={{width:'100%'}}  variant={'outlined'} value={data.offer_id}
     focused={editRedirection1?true:false}   onChange={(e)=>setData({...data,offer_id:e.target.value})}
         label='Offer ID' placeholder='Enter offer id' />
        <Grid item sm={12} style={{padding:'2vh 0px'}} xs={12}>

        <TextField   style={{width:'100%'}}  variant={'outlined'} value={data.pid}
     focused={editRedirection1?true:false}   onChange={(e)=>setData({...data,pid:e.target.value})}
         label='PID' placeholder='Enter pid' />
        </Grid>
        <Grid item sm={12} style={{padding:'2vh 0px'}} xs={12}>

<TextField   style={{width:'100%'}}  variant={'outlined'} value={data.redirection_url}
focused={editRedirection1?true:false}   onChange={(e)=>setData({...data,redirection_url:e.target.value})}
 label='Redirection URL' placeholder='Enter redirection url' />
</Grid>
        </Grid>
        <div style={{width:'100%',textAlign:'center',paddingTop:'1vh'}}>
{editRedirection1?        <Chip onClick={()=>editRedirectionClicked()}   label={loadingE?"Saving": 'Save'}  className='chip_new'/>
:<Chip onClick={()=>addRedirectionClicked()}   label={loading?"Processing": 'Add'}  className='chip_new'/>}
        </div>
        </Grid>
        </DialogContent>
        
       
      </Dialog>


        <Grid container sm={12} xs={12}>

<Grid item sm={12} style={{textAlign:'-webkit-right',padding:'5vh 2vw'}} xs={12}>


<Chip  label='Add new redirection' onClick={()=>setOpen1(true)} className='chip_new' />
<Chip  label='Signout' onClick={()=>Signout()} style={{marginLeft:10,background:'black',color:'white'}} />

</Grid>
        </Grid>
        



        <Grid item sm={12} xs={12}>

        {
        

        (redirections&&redirections.redirections[0]&&!loadingRedirection?
      (redirections.redirections.map((val,idx)=>
        <Accordion key={idx}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography style={{color:'#ec7539'}}>Redirection {idx+1}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <Grid container>
             

                <Grid container sm={11} xs={11}>
                <Grid item sm={12} xs={12}>

                   <Typography  style={{fontSize:19,color:'#ec7539'}}>
                <b>Offer ID : </b>   {val.offer_id}
                    
                    </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>
                <Typography style={{fontSize:17,fontWeight:'400'}}>
                <b>PID : </b>  {val.pid}

                    
                    </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

                <Typography color={'text.secondary'}  style={{fontSize:15,fontWeight:'400'}}>
                <b>Redirection URL : </b>   {val.redirection_url}
                    
                    </Typography> 
                </Grid> 
                <Grid item sm={12} xs={12}>

<Typography color={'text.secondary'}  style={{fontSize:15,fontWeight:'400'}}>
<b>Exact URL : </b>   {`gettracker.online/?offer_id=${val.offer_id}&pid=${val.pid}&redirection_url=${val.redirection_url}`}
    
    </Typography> 
</Grid> 
               

                </Grid> 
                <Grid item sm={1} xs={1}>

                <i onClick={()=>EditRedirection(val)}  style={{color:'#ec7539',fontSize:20,cursor:'pointer'}} class="far fa-edit"></i>

                </Grid> 
            </Grid>
        
        </AccordionDetails>
      </Accordion> ))
        :
      <Grid item sm={12} xs={12}>
      <Typography style={{paddingTop:'5vh',textAlign:'center',color:'#ec7539',fontSize:'1.2rem',fontWeight:'700'}}>
          No Redirections Added
      </Typography>
      
      </Grid>)}
      
     

        </Grid>
    </Grid>}
    </div>
  )
}
