import React, { useEffect, useState } from 'react'
import {CircularProgress} from '@mui/material'
import { useParams } from 'react-router-dom'
import { useRedirectPageMutation } from '../slices/siteSlice';

export default function Home() {

    const urlParams = new URLSearchParams(window.location.search);
let offer_id=urlParams.get('offer_id')
let pid=urlParams.get('pid')
let click_id=urlParams.get('click_id')
// let redirection_url=urlParams.get('redirection_url')

const [redirectPage,{isLoading:loading}]=useRedirectPageMutation()
const [error,setError]=useState(false)
    // console.log(offer_id,pid,click_id,redirection_url)

useEffect(()=>{
if(offer_id&&pid)
 { async function RedirectPage(){
  setError(false)
    let redirection_url=window.location.search.replace(`?offer_id=${offer_id}&pid=${pid}&redirection_url=`,'')
    let data={offer_id,pid,redirection_url}
try {
  const res=await redirectPage(data).unwrap()

    if(res&&res.message){
      window.location.replace(res.value)
    }
    else{
      setError(true)
    }
} catch (error) {
  if(error){
    setError(true)
  }

  
}
    

  }
  RedirectPage()}
  else{
    setError(true)
  }

},[])

  return (

    <div>
  {!error?  <div style={{textAlign:'center',minHeight:'100vh',marginTop:'40vh'}}>

<CircularProgress />


    </div>
   : <img src={require('../image/errorImage.png')}  style={{objectFit:'contain',width:'100%',height:'70vh'}}/>
  }
    </div>
  )
}
