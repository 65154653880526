



import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import './dashboard.css'
import { useLocation,useNavigate } from 'react-router-dom'
import React, { useState } from 'react';
import {useSelector,useDispatch} from 'react-redux';
import { setCredentials } from '../slices/authSlice';
// import bcrypt from 'bcrypt';
import {toast} from 'react-toastify';

import  {sha256}  from 'js-sha256'
import { useEffect } from 'react';
import { useUserLoginMutation } from '../slices/siteSlice';
import MainComponent from './mainComponent';

// import Dashboard from '../dashboard/dashboard';
export default function Signin() {

  const [active,setActive]=useState('Signin')
  const [data,setData]=useState({})
const dispatch =useDispatch()
const navigate=useNavigate()

const [login,{isLoading:loading}]=useUserLoginMutation()

// console.log(loading)
const {userInfo}=useSelector((state)=>state.auth)


// useEffect(() => {

//     if(userInfo){
//      navigate('/')   
//     }

// }, [userInfo,navigate])

const signINClicked=async()=>{

// console.log(data.username,sha256(data.password))



    try{
        const res=await login({username:data.username,password:sha256(data.password)}).unwrap()
        dispatch(setCredentials(res.data))
        toast.success(res.message)
window.location.reload()
    }catch(error){
toast.error(error?.data?.message||error.error)
    }

}

  return (
<div>

    {userInfo?    <div><MainComponent /></div>
:<div className='bg_signin'>

<Paper style={{minheight:400,background:'var(--blueColor)',width:400,borderRadius:'5%',border:'2px solid #ec7539'}}>
{
<Grid container  className='signin_cont'>
<Grid item sm={12} xs={12}>


  </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'#ec7539',fontWeight:'600',fontSize:19,padding:'1vh 0px'}}>Sign in to continue ..</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'#ec7539',fontWeight:'500',fontSize:16,padding:'3vh 10px 1vh 10px',textAlign:'start'}}>Username</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        
        // label="TextField"
value={data.username}
        onChange={(e)=>setData({...data,username:e.target.value})}
        placeholder='Enter username'
        type='username'
        variant="standard"
      />

    </Grid>
    <Grid item sm={12} xs={12}>
        <Typography style={{color:'#ec7539',fontWeight:'500',fontSize:16,padding:'1vh 10px',textAlign:'start'}}>Password</Typography>
    </Grid>
    <Grid item sm={12} xs={12}>
    <TextField
            style={{background:'rgba(255,255,255,0.6)',border:'none',width:'90%',borderRadius:6,padding:7,fontSize:16,fontWeight:'bold',margin:'1vh 0px'}}
        // label="TextField"
        placeholder='Enter password'
        onChange={(e)=>setData({...data,password:e.target.value})}
        value={data.password}

        type='password'
        variant="standard"
      />

    </Grid>

    <Grid item sm={12} xs={12}>
   {/* <Button   style={{background:'#ec7539',color:'white',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{' Sign in'}</Button> */}
   <Button onClick={()=>signINClicked()}  style={{background:'#ec7539',color:'white',padding:'5px 2vw',marginTop:'2vh',fontWeight:'600'}} >{' Sign in'}</Button>

    </Grid>
 
</Grid>
}


</Paper>

    </div>}
    </div>
  )
}

