import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Home from './components/home';
import Dashboard from './components/dashboard';

function App() {
  return (
    <BrowserRouter>
  
    <Routes>
    <Route  path='/' element={<Home />} />
    <Route  path='/red-gate' element={<Dashboard />} />

    </Routes>

    <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
